export const environment = {
  production: true,
  // baseURL: 'https://royalcasino-api.roomruler.com/',
  // apiURL: 'https://royalcasino-api.roomruler.com/',
  // mediaURL: 'https://royalcasino-api.roomruler.com/file/',
  // wsURL: 'wss://royalcasino-api.roomruler.com',
  // authToken: 'anything',
  // encryptionKey: 'aisidns8882IOUm2',
  // ipUrl: 'https://api.ipify.org?format=json',
  versionNo: '1.0.1'
};
